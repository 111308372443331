import {
  Grid,
  Container,
} from '@mui/material';
// test 
import Cards from './Cards';
import Chart from './Chart';
import TableDash from './TableDash';


function Dashboard() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          mt={1}
        >
          <Grid item xs={12} >
            <Cards/>
          </Grid> 
          <Grid item xs={12}>
            <TableDash/>
          </Grid>
          <Grid  item xs={12}>
            <Chart/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;