import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Zone } from "../zone.type";

export class ZoneConverter implements FirestoreDataConverter<Zone>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Zone, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Zone 
    {
        const data = snapshot.data();
        const zone: Zone = {
            id: data.id,
            name: data.name,
            location: data.location,
            city: data.city,
        };

        return zone;
    }

}