import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/system';
import { Card, Grid } from '@mui/material';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Chart',
      },
    },
};
  
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Dataset 2',
        data: [350, 200, 220, 400, 785, 600, 300],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
};

function Chart() {

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Card 
        sx={{
          px: 1,
          py: 1,
          my: 2,
          mx: 1
        }}>
          <Box mb={2}  >
            <Line options={options} data={data} redraw={true}/>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card 
          sx={{
            px: 1,
            py: 1,
            my: 2,
            mx: 1
          }}
        >
          <Box mb={2}>
            <Line options={options} data={data} redraw={true}/>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Chart;