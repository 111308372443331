import { Route, Navigate, RouteProps, Outlet } from 'react-router-dom';
import {app} from '../../../firebase'
import {getAuth} from 'firebase/auth'
const auth = getAuth(app);

 interface Props extends RouteProps {
     auth: any
 }
  const PrivateRoute = ({auth, ...routeProps}: Props) => {
     if (!auth) {
         return <Navigate to="/login" />;
     } else {
         <Route {...routeProps} />;
     }
     return <Outlet/>
 }


export default PrivateRoute;