import {
  Box,
  Card,
  CardHeader,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@mui/material';
import { Container } from '@mui/system';
import { ChangeEvent, useState } from 'react';

const devices: DevicePagination[] = [
  {id: 1,device: 'الشواهده',off: '5hr',on: '19hr'},
  {id: 2, device: 'الشواهده', off: '5hr', on: '19hr'},
  {id: 3,device: 'الرويسات',off: '5hr',on: '19hr'},
  {id: 4,device: 'الرملة',off: '8hr',on: '16hr'},
  {id: 5,device: 'الرملة',off: '8hr',on: '16hr'},
  {id: 6,device: 'الرملة',off: '8hr',on: '16hr'},
  {id: 7,device: 'الجزيرة',off: '8hr',on: '16hr'},
  {id: 8,device: 'الجزيرة',off: '5hr',on: '19hr'},
  {id: 9,device: 'الجزيرة',off: '5hr',on: '19hr'},
  {id: 10,device: 'الرويسات',off: '5hr',on: '19hr'},
  {id: 11,device: 'الغيران',off: '4hr',on: '20hr'},
  {id: 12,device: 'الغيران',off: '4hr',on: '20hr'},
  {id: 13,device: 'الغيران',off: '4hr',on: '20hr'},
  {id: 14,device: 'الغيران',off: '4hr',on: '20hr'},
  {id: 15,device: 'الرويسات',off: '5hr',on: '19hr'},
  {id: 16,device: 'الرويسات',off: '5hr',on: '19hr'},
  {id: 17,device: 'كرزاز',off: '5hr',on: '19hr'},
  {id: 18,device: 'كرزاز',off: '5hr',on: '19hr'},
  {id: 19,device: 'كرزاز',off: '5hr',on: '19hr'},
  {id: 20,device: "قزير",off: '5hr',on: '19hr'},
  {id: 21,device: "قزير",off: '5hr',on: '19hr'},
  {id: 22,device: "قزير",off: '5hr',on: '19hr'},
  {id: 23,device: 'طمينة',off: '5hr',on: '19hr'},
  {id: 24,device: 'طمينة',off: '5hr',on: '19hr'},
  {id: 25,device: 'طمينة',off: '5hr',on: '19hr'},
];

interface DevicePagination {
    id: number,
    device: string,
    off: string,
    on: string
}

const applyPagination = (
    devices: DevicePagination[],
    page: number,
    limit: number
  ): DevicePagination[] => {
    return devices.slice(page * limit, page * limit + limit);
  };

function Device() {
    
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
        };

        const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const dataTablePagin = applyPagination(
        devices,
        page,
        limit
    )

  return (
    <Container maxWidth="lg">
      <Card sx={{ my: 2 }}>
        <CardHeader
          action={<Box width={150}></Box>}
          title="Average time on/off"
        />
        <Divider />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">id</TableCell>
                <TableCell align="center">device</TableCell>
                <TableCell align="center">avg off</TableCell>
                <TableCell align="center">avg on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {dataTablePagin.map((obj) => {
                    return(
                    <TableRow key={obj.id}>
                        <TableCell align="center">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {obj.id}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {obj.device}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {obj.off}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {obj.on}
                          </Typography>
                        </TableCell>
                    </TableRow>
                    )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
        <TablePagination
          component="div"
          count={devices.length} 
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      </Card>
    </Container>
  );
}
export default Device;
