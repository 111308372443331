import configs from './elec-a52f1-firebase-adminsdk-psf9x-830c30ee5a.json';
import firebase from 'firebase/compat/app';
const firebaseConfig = {
    apiKey: "AIzaSyDKepQSvi0V7emky8eoWGXjClJcirmqow4",
    authDomain: "elec-a52f1.firebaseapp.com",
    databaseURL: "https://elec-a52f1-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "elec-a52f1",
    storageBucket: "elec-a52f1.appspot.com",
    messagingSenderId: "37126425509",
    appId: "1:37126425509:web:cefece1c5fc2cf5047f64c",
    measurementId: "G-MYMK1HQCNJ"
  };

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);