import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import Add from './Add';

import { app } from '../../../firebase';
import {
  getFirestore,
  collection,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { DeviceConverter } from 'src/firebase/firestore/convertors/device.convertor';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { StatusConverter } from 'src/firebase/firestore/convertors/status.converter';


const firestore = getFirestore(app);
const devicesCollection = collection(firestore, 'devices').withConverter(
  new DeviceConverter()
);
const statusCollection = collection(firestore,'status').withConverter(
  new StatusConverter()
);

let currentDevice = null;

function DevicesTable() {
  const [fbDevices, setFbDevices] = useState([]);
  const [fbStatus, setFbStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [isloading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDevices = async (): Promise<any> => {
      onSnapshot(devicesCollection, (d) => {
        setFbDevices(d.docs.map((d) => d.data()));
      });
    };
    getDevices();

    const getStatus = async (): Promise<any> => {
      await onSnapshot(statusCollection, (d) => {
        setFbStatus(d.docs.map((d) => d.data()));
      });
    };
    getStatus();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose: () => void = () => {
    setOpen(false);
    currentDevice = null;
  };
  const handleCloseDelete: () => void = async () => {
    setOpenDelete(false);
  };
  const handleEditClick = (d: any) => {
    currentDevice = d;
    setOpen(true);
  };
  const handleDeleteItem = async () => {
    setIsLoading(true)
    if (deleteId) {
      await deleteDoc(doc(devicesCollection, deleteId.toString()));
    }
    setOpenDelete(false);
    setIsLoading(false)
  };


  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={handleClickOpen}
            >
              Add Device
            </Button>
          </Box>
        }
        title="Devices Table"
      />
      <Divider />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Add handleClose={handleClose} device={currentDevice} />
        </DialogContent>
      </Dialog>
      { isloading ? <SuspenseLoader/> :
        <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this item ?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button onClick={handleDeleteItem} autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        }
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>status</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fbDevices.map((d) => {
              return (
                <TableRow key={d.id}>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d.type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d?.location?.latitude}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d?.location?.longitude}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {d.notes}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                      <FormControlLabel label=""  control={<Switch checked={d.enabled} />} />
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel label=""  control={ fbStatus[d.id]?.status? <Switch checked={true}/> : <Switch checked={false}/>} />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="info"
                      onClick={() => {
                        handleEditClick(d);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="info"
                      onClick={() => {
                        setDeleteId(d.id);
                        handleClickOpenDelete();
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default DevicesTable;
