import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue
} from 'firebase/firestore';
import { Device } from '../device.type';

export class DeviceConverter implements FirestoreDataConverter<Device> {
  toFirestore(modelObject: WithFieldValue<Device>): DocumentData;
  toFirestore(
    modelObject: PartialWithFieldValue<Device>,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: Device,
    options?: unknown
  ): import('@firebase/firestore').DocumentData {
    return { ...modelObject };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): Device {
    const data = snapshot.data();
    const device: Device = {
      id: data.id,
      enabled: data.enabled,
      location: data.location,
      name: data.name,
      status: data.status,
      type: data.type,
      notes: data.notes
    };

    return device;
  }
}
