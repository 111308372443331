import { Navigate, useRoutes } from 'react-router-dom';
// import router from 'src/router';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import SignInScreen from './firebase/login';
import Devices from './content/dashboards/Devices';
import { Suspense, useState } from 'react';

import PrivateRoute from './content/dashboards/PrivateRoute';

import {app} from './firebase'
import {getAuth} from 'firebase/auth'
import Cities from './content/dashboards/Cities';
import Zones from './content/dashboards/Zones';
import SidebarMenu from './layouts/SidebarLayout/Sidebar/SidebarMenu';
import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';
import SuspenseLoader from './components/SuspenseLoader';
import Dashboard from './content/dashboards/Dashboard';
import Device from './content/statis/Device';
import City from './content/statis/City';
import Zone from './content/statis/Zone';
const auth = getAuth(app);

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(auth.currentUser);
  auth.onAuthStateChanged((user)=>{
    setIsAuth(user);
    setLoading(false)
  });

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
          {!loading ?
          (
            <Router>
              <Routes>
                <Route path="/login" element={<SignInScreen/>} />
                <Route path="/" element={<PrivateRoute auth={isAuth} />}>                
                  <Route path="" element={<SidebarLayout/>} >
                    <Route path='/' element={<Navigate to="/dashboard"/>}/>
                    <Route path='/dashboard' element={<Dashboard/>} />
                    <Route path='/devices' element={<Devices/>} />
                    <Route path='/cities' element={<Cities/>} />
                    <Route path='/zones' element={<Zones/>} />
                    <Route path='/statis/device' element={<Device/>} />
                    <Route path='/statis/city' element={<City/>} />
                    <Route path='/statis/zone' element={<Zone/>} />
                  </Route> 
                </Route>
              </Routes>
            </Router>
          )
          :
          <SuspenseLoader/>
          }
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
