import { Button, Card, Dialog, DialogContent, FormControl, Grid, Input, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {  useState } from "react";
import { useForm } from "react-hook-form";

import Map from "../../../map";
import { useLoadScript } from "@react-google-maps/api";
import ErrorMessage from "../error";
import { collection, doc, getFirestore, setDoc, GeoPoint, onSnapshot, DocumentReference} from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ZoneConverter } from "src/firebase/firestore/convertors/zone.converter";
import { Zone } from "src/firebase/firestore/zone.type";

const firestore = getFirestore(app);
const zonesCollection = collection(firestore,'zones').withConverter( new ZoneConverter());

type handleClose = {
    handleClose:  () => void;
    zone: any;
    pCities: any;
}

function Add(props: handleClose) {
  const [id, setId] = useState<number>(props.zone?.id);
  const [zone, setZone] = useState<string>(props.zone?.name);
  const [cityOfZone, setCityOfZone] = useState(props.zone?.city?.id);
  const [latitude, setLatitude] = useState<number>(props.zone?.location?.latitude);
  const [longitude, setLongitude] = useState<number>(props.zone?.location?.longitude);
  const [ cities, setCities ] = useState(props.pCities);
  const [isLoading, setIsLoading] = useState(false);

    
  const onSubmit = async () => {     
    setIsLoading(true);
    const zoneModel: Zone = {
      id:id,
      name: zone,
      city: doc(firestore,`cities/${cityOfZone}`),
      location: new GeoPoint(latitude,longitude)
    }
    try{
     await setDoc(doc(zonesCollection,id.toString()), zoneModel);
    setIsLoading(false);
    props.handleClose();
  }catch(e){
  }
  }

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Load Google Map Function
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAY3-TEpfKzSW_xf-X8vVmDHEIaGjrOfio",
    language: "ar",
  });
    
  const mapChange = (data) => {
    setValue("zoneLatitudeValidation", data?.lat, { shouldTouch: true, shouldValidate: true });
    setValue("zoneLongitudeValidation", data?.lng, { shouldTouch: true, shouldValidate: true });
    setLatitude(data?.lat);
    setLongitude(data?.lng);
  };

  // set City from dropdown menu
  const handleChange = (e,v) => {
    setCityOfZone(v.props.value);
  };

  return (
      
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
            Add Zone
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <Input
                type="number"
                placeholder="ID"
                value={id}
                {...register("zoneIdValidation", {
                  required: "Please Insert An Id",
                  min: { message: "invalid Id", value: 1 },
                  pattern: { value: /^[0-9]*$/, message: "invalid id" },
                })}
                fullWidth
                onChange={(e) => setId(+e.target.value)}
                autoFocus
                />
                {errors.zoneIdValidation && <ErrorMessage message={errors.zoneIdValidation.message} />}
            </Box>
            <Box mb={3}>
              <Input
                  type="text"
                  placeholder="Zone Name"
                  value={zone}
                  {...register("zoneNameValidation", {
                    required: "Please Insert Name Of Zone",
                    pattern: { value: /^[a-zA-Z\u0621-\u064A]*$/, message: "invalid name" }
                  })}
                  fullWidth
                  onChange={(e) => setZone(e.target.value)}
                />
                {errors.zoneNameValidation && <ErrorMessage message={errors.zoneNameValidation.message} />}
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cityOfZone}
                    label="City"
                    onChange={(e,v)=>handleChange(e,v)}
                    sx={{ p: 0, my: 1 }}
                  >
                    {
                      Object.keys(cities).map(k=>{
                        return <MenuItem key={cities[k]?.id} value={cities[k]?.id}  > {cities[k]?.name} </MenuItem>
                      })
                    }
                    
                  </Select>
                </FormControl>
            </Box>
            <Box mb={3}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Input
                    type="text"
                    placeholder="Latitude"
                    value={latitude}
                    {...register("zoneLatitudeValidation", {
                      required: "Please Insert Latitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{1,6}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLatitude(+e.target.value)}
                    />
                    {errors.zoneLatitudeValidation && <ErrorMessage message={errors.zoneLatitudeValidation.message} />}
                  </Grid>
                  <Grid item xs={6}>
                  <Input
                    type="text"
                    placeholder="Longitude"
                    value={longitude}
                    {...register("zoneLongitudeValidation", {
                      required: "Please Insert Latitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{1,6}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLongitude(+e.target.value)}
                    />
                    {errors.zoneLongitudeValidation && <ErrorMessage message={errors.zoneLongitudeValidation.message} />}
                  </Grid>
                </Grid>
            </Box>
            <Box mb={3}>
              {isLoaded && (
                <Map
                  center={{ lat: 32.352612, lng: 15.05911 }}
                  markerLat={latitude}
                  markerLng={longitude}
                  onChange={(data) => mapChange(data)}
                />
              )}
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.zone?'Update': 'Add'}</Button>
            </Box>
        </Box>
      </Box> 
    </>
      
         
      
  )
}

export default Add;