import {
    Card,
    Grid,
    Box,
    CardContent,
    Typography,
    Avatar,
    alpha,
    styled,
  } from '@mui/material';

  const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        margin: ${theme.spacing(2, 0, 1, -0.5)};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing(0)};
        margin-bottom: ${theme.spacing(2)};
        border-radius: 60px;
        height: ${theme.spacing(8.5)};
        width: ${theme.spacing(8.5)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[30]
            : alpha(theme.colors.alpha.black[100], 0.07)
        };
      
        img {
          background: ${theme.colors.alpha.trueWhite[100]};
          padding: ${theme.spacing(0.5)};
          display: block;
          border-radius: inherit;
          height: ${theme.spacing(7.5)};
          width: ${theme.spacing(7.5)};
        }
    `
  );

function Cards() {
    return(
        <Grid container spacing={3}>
        <Grid item textAlign="center" xs={12} md={4} >
              <Card
                sx={{
                  px: 1,
                  py: 1
                }}
              >
                <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box >
                    <AvatarWrapper>
                        <img
                        alt="BTC"
                        src="/static/images/placeholders/logo/device77.jpg"
                        />
                    </AvatarWrapper>
                  </Box>
                  <Box >
                    <Typography variant="h3" noWrap>
                        Devices
                    </Typography>
                    <Box
                        sx={{
                        pt: 1
                        }}
                    >
                        <Typography variant="h3" gutterBottom noWrap>
                        100
                        </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid textAlign="center" xs={12} md={4} item>
              <Card
                sx={{
                  px: 1,
                  py: 1
                }}
              >
                <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                  <AvatarWrapper>
                    <img
                      alt="cities"
                      src="/static/images/placeholders/logo/city.png"
                    />
                  </AvatarWrapper>
                  </Box>
                  <Box>
                    <Typography variant="h3" noWrap>
                        Cities
                    </Typography>
                    <Box
                        sx={{
                        pt: 1
                        }}
                    >
                        <Typography variant="h3" gutterBottom noWrap>
                        15
                        </Typography>
                    </Box>

                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid textAlign="center" xs={12} md={4} item>
              <Card
                sx={{
                  px: 1,
                  py: 1
                }}
              >
                <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                    <AvatarWrapper>
                        <img
                        alt="cities"
                        src="/static/images/placeholders/logo/zone1.png"
                        />
                    </AvatarWrapper>
                  </Box>
                  <Box>
                    <Typography variant="h3" noWrap>
                        Zones
                    </Typography>
                    <Box
                        sx={{
                        pt: 1
                        }}
                    >
                        <Typography variant="h3" gutterBottom noWrap>
                        75
                        </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
    )
}

export default Cards;