import { Button, Card, FormControlLabel, FormGroup, Grid, Input, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Map from "../../../map";
import { useLoadScript } from "@react-google-maps/api";
import ErrorMessage from "../error";
import { collection, doc, getFirestore, setDoc, GeoPoint } from "firebase/firestore";
import { app } from "src/firebase";
import SuspenseLoader from "src/components/SuspenseLoader";
import { DeviceConverter } from "src/firebase/firestore/convertors/device.convertor";
import { Device } from "src/firebase/firestore/device.type";
import { Label } from "@mui/icons-material";

const firestore = getFirestore(app);
const devicesCollection = collection(firestore,'devices').withConverter(new DeviceConverter());
const statusCollection = collection(firestore,'status');

type handleClose = {
    handleClose:  () => void;
    device: any;
}

function Add(props: handleClose) {

  const [id, setId] = useState<number>(props.device?.id);
  const [type, setType] = useState<string>(props.device?.type);
  const [name, setName] = useState<string>(props.device?.name);
  const [enable, setEnable] = useState<boolean>(props.device?.enabled);
  const [status, setStatus] = useState(props.device?.status);
  const [notes, setNotes] = useState<string>(props.device?.notes);
  const [latitude, setLatitude] = useState<number>(props.device?.location?.latitude);
  const [longitude, setLongitude] = useState<number>(props.device?.location?.longitude);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true); 
    const deviceModel: Device = {
      id: id,
      name: name,
      type: 'V1',
      notes: notes??'',
      enabled: !!enable,
      status: status??doc(statusCollection,`${id}`),
      location: new GeoPoint(latitude,longitude)
    };
    await setDoc(doc(devicesCollection,id.toString()), deviceModel);
    setIsLoading(false);
    props.handleClose();
  };

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnable(e.target.checked);
  }

  // function use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Load Google Map Function
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAY3-TEpfKzSW_xf-X8vVmDHEIaGjrOfio",
    language: "ar",
  });

  const mapChange = (data) => {
    setValue("deviceLatitudeValidation", data?.lat, { shouldTouch: true, shouldValidate: true });
    setValue("deviceLongitudeValidation", data?.lng, { shouldTouch: true, shouldValidate: true });
    setLatitude(data?.lat);
    setLongitude(data?.lng);
  };
  return (
    isLoading? <SuspenseLoader/> : 
    <>
      <Box
        bgcolor="info"
        borderRadius="lg"
        mx={1}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <Typography  variant="h3" fontWeight="bold" color="info" mt={2}>
          Add Device
        </Typography>
      </Box>
      <Box  py={3} px={3}>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
                <Input
                type="number"
                placeholder="ID"
                value={id}
                {...register("deviceIdValidation", {
                  required: "Please Insert An Id",
                  min: { message: "invalid Id", value: 1 },
                  pattern: { value: /^[0-9]*$/, message: "invalid id" },
                })}
                fullWidth
                onChange={(e) => setId(+e.target.value)}
                autoFocus
                />
                {errors.deviceIdValidation && <ErrorMessage message={errors.deviceIdValidation.message} />}
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Device Name"
                value={name}
                {...register("deviceNameValidation", {
                  required: "Please Insert Name Of Device",
                  pattern: { value: /^[a-z A-Z0-9\u0621-\u064A]*$/, message: "invalid name" },
                })}
                fullWidth
                onChange={(e) => setName(e.target.value)}
                />
                  {errors.deviceNameValidation && <ErrorMessage message={errors.deviceNameValidation.message} />}
            </Box>

            <Box mb={3} textAlign="center">
              <FormControlLabel control={<Switch checked={enable} onChange={handleChangeCheck} />} label="Enable" />
            </Box>

            <Box mb={3}>
                <Input
                type="text"
                placeholder="Type"
                value={type}
                {...register("deviceTypeValidation", { required: true })}
                fullWidth
                onChange={(e) => {
                  setType(e.target.value);
                }}
                />
                  {errors.deviceTypeValidation && <ErrorMessage message={errors.deviceTypeValidation.message} />}
            </Box>
            <Box mb={3}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Input
                    type="text"
                    placeholder="Latitude"
                    value={latitude}
                    {...register("deviceLatitudeValidation", {
                      required: "Please Insert Latitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{2,}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLatitude(+e.target.value)}
                    />
                    {errors.deviceLatitudeValidation && <ErrorMessage message={errors.deviceLatitudeValidation.message} />}
                  </Grid>
                  <Grid item xs={6}>
                  <Input
                    type="text"
                    placeholder="Longitude"
                    value={longitude}
                    {...register("deviceLongitudeValidation", {
                      required: "Please Insert Longitude",
                      pattern: { value: /^-?([1-8]?[0-9])\.{1}\d{2,}/, message: "invalid id" },
                    })}
                    fullWidth
                    onChange={(e) => setLongitude(+e.target.value)}
                    />
                    {errors.deviceLongitudeValidation && (<ErrorMessage message={errors.deviceLongitudeValidation.message} /> )}
                  </Grid>
                </Grid>
            </Box>
            <Box mb={3}>
              {isLoaded && (
                <Map
                  center={{ lat: 32.352612, lng: 15.05911 }}
                  markerLat={latitude}
                  markerLng={longitude}
                  onChange={(data) => mapChange(data)}
                />
              )}
            </Box>
            <Box mb={3}>
                <Input
                type="text"
                placeholder="Notes"
                value={notes}
                {...register("deviceNoteValidation", { required: false })}
                fullWidth
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                />
                {errors.deviceNoteValidation && <ErrorMessage message={errors.deviceNoteValidation.message} />}
            </Box>
            <Box mb={1}>
                <Button color="info" type="submit" fullWidth >{props.device? 'Update': 'Add'}</Button>
            </Box>
        </Box>
      </Box>
    </>
  )
}

export default Add;