import { Button, Card, CardHeader, Dialog, DialogActions, DialogContentText, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Add from './Add'
import { app } from '../../../firebase'
import { getFirestore, collection, QuerySnapshot, DocumentData, onSnapshot, deleteDoc, doc } from 'firebase/firestore'
import { getAuth, } from "firebase/auth";
import { CityConverter } from "src/firebase/firestore/convertors/city.converer";

const firestore = getFirestore(app);
const citiesCollection = collection(firestore,'cities').withConverter(new CityConverter());
let currentCity = null;

function TableData() {
        
    const [deleteId, setDeleteId] = useState<number>();
    const [ fbCities, setCities ] = useState([]);
    useEffect(() => {
        // onLoad();
        const getCities = async (): Promise<any>=>{
            onSnapshot(citiesCollection,(d)=>{
                setCities(d.docs.map(d=>d.data()));
            });
        }
        getCities();
    }, [])

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
   

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleEditClick = (city: any) => {
        currentCity = city;
        setOpen(true);
    };
    const handleClose: () =>  void = async () => {
        setOpen(false);
        currentCity = null;
    };
    const handleCloseDelete: () =>  void = async () => {
        setOpenDelete(false);
    };
    const handleDeleteItem = async () => {
        if(deleteId) {
            await deleteDoc(doc(citiesCollection, deleteId.toString()));
        }
        setOpenDelete(false);
    }

    return (
        <Card>
            <CardHeader 
            action={
                <Box width={150}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                    Add City
                    </Button>
                </Box>
            }
            title="Cities Table" />
            <Divider />
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Add handleClose={handleClose} city={currentCity} />
                </DialogContent>
            </Dialog>

            <Dialog 
              open={openDelete} 
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancel</Button>
                    <Button onClick={handleDeleteItem} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>latitude</TableCell>
                            <TableCell>longitude</TableCell>
                            <TableCell align="center">count zone</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {
                            fbCities.map((city) => {
                                return (
                                    <TableRow key={city.id}>
                                    
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                            {city.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city?.location?.latitude}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                                {city?.location?.longitude}
                                            </Typography>
                                        </TableCell>
                                        <TableCell  align="center">
                                            5
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                            color="info"
                                            onClick={() => {
                                                handleEditClick(city);
                                            }}
                                            >
                                            Edit
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Button
                                            color="info"
                                            onClick={() => {
                                                setDeleteId(city.id);
                                                handleClickOpenDelete();
                                            }}
                                            >
                                            Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default TableData;