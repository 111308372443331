import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { Status } from "../status.type";

export class StatusConverter implements FirestoreDataConverter<Status>{
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: Status, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }
    
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions): Status {
        const data = snapshot.data();
        const status: Status = {
            id: data.id,
            status: data.status,
        };
        return status;
    }
    
}