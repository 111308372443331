import { Box, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Add from './Add'
import { app } from '../../../firebase'
import { getFirestore, collection, getDocs, DocumentData, getDoc, onSnapshot, deleteDoc, doc } from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import SuspenseLoader from "src/components/SuspenseLoader";
const firestore = getFirestore(app);
const zonesCollection = collection(firestore,'zones');
const citiesCollection = collection(firestore,'cities');
const auth = getAuth(app);


let currentZone = null;

function TableData() {

    const [ fbZones, setFbZones ] = useState([]);
    const [ fbCities, setCities ] = useState({});
    const [open, setOpen] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>();
    const [isloading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getCities = async (): Promise<any>=>{
            onSnapshot(citiesCollection,async (d) => {
                const cities = {};
                 d.docs.forEach(d=>{
                    cities[d.id] = d.data();
                });
                setCities(cities);   
            });
         }
         getCities();
        const getZones = async (): Promise<any> => {
          onSnapshot(zonesCollection,(d) => {
            setFbZones(d.docs.map(d=>d.data()));
          });
        }
        getZones();
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleEditClick = (zone: any) => {
        currentZone = zone;
        setOpen(true);
    };
    const handleClose: () =>  void = () => {
        setOpen(false);
        currentZone = null;
    };
    const handleCloseDelete: () =>  void = async () => {
        setOpenDelete(false);
    };
    const handleDeleteItem =  async ()  => {
        setIsLoading(true)
        if(deleteId){
            await deleteDoc(doc(zonesCollection,deleteId.toString()));
        }
        setOpenDelete(false);
        setIsLoading(false)
    };

    return (
        <Card>
            <CardHeader 
            action={
                <Box width={150}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                    Add Zone
                    </Button>
                </Box>
            }
            title="Zones Table" />
            <Divider />
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Add handleClose={handleClose} zone={currentZone} pCities={fbCities}/>
                </DialogContent>
            </Dialog>
            { isloading ? <SuspenseLoader/> : 
            <Dialog 
              open={openDelete} 
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancel</Button>
                    <Button onClick={handleDeleteItem} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            }
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Zone</TableCell>
                            <TableCell>city of zone</TableCell>
                            <TableCell>latitude</TableCell>
                            <TableCell>longitude</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {
                            fbZones.map((zone) => {
                                return (
                                    <TableRow key={zone.id}>
                                    
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                            {zone.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                            {zone.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >   
                                            {fbCities[zone.city.id]?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography

                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                            {zone?.location?.latitude}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color="text.primary"
                                            gutterBottom
                                            noWrap
                                            >
                                            {zone?.location?.longitude}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                            color="info"
                                            onClick={() => {
                                                handleEditClick(zone);
                                            }}
                                            >
                                            Edit
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                        <Button
                                            color="info"
                                            onClick={() => {
                                                handleClickOpenDelete();
                                                setDeleteId(zone.id)
                                            }}
                                            >
                                            Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default TableData;