import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SetOptions, SnapshotOptions } from "firebase/firestore";
import { City } from "../city.type";

export class CityConverter implements FirestoreDataConverter<City> {
    toFirestore(modelObject: any): DocumentData;
    toFirestore(modelObject: any, options: SetOptions): DocumentData;
    toFirestore(modelObject: City, options?: unknown): import("@firebase/firestore").DocumentData {
        return { ...modelObject };
    }
    
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): City {
        const data = snapshot.data();
        const city: City = {
            id: data.id,
            name: data.name,
            location: data.location,
        };
        
        return city;
    }
    
}